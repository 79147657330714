<template>
    <div>
        <div class="setting_content">
            <div class="setting_title" style="border-bottom: 1px solid #EFEFEF;">
                羊场1大屏设置
            </div>
            <div class="setting_content_content">
              <!-- 基本信息 -->
              <BasicInformation></BasicInformation>
              <div class="line"></div>
              <CameraList></CameraList>
              <div class="line"></div>
              <IotMeteorologicalList></IotMeteorologicalList>
              <div class="line"></div>
              <SheepIndustry></SheepIndustry>
              <div class="line"></div>
              <SheepUser></SheepUser>
              <div class="line"></div>
              <enterprise></enterprise>
              <div class="line"></div>
              <!-- 榆阳区羊肉价格指数图  ---- 黄色点点 -->
              <SheepEvaluationPrice></SheepEvaluationPrice>
              <div class="line"></div>
              <SheepShop></SheepShop>
               <div class="line"></div>
              <SheepEvaluationAveragePrice></SheepEvaluationAveragePrice> 
            </div>
        </div>
    </div>
</template>

<script>
import BasicInformation from './components/dataScreen1_sheep/basicInformation.vue'
import SheepIndustry from './components/dataScreen1_sheep/sheepIndustry.vue'
import CameraList from './components/dataScreen1_sheep/cameraList.vue'
import IotMeteorologicalList from './components/dataScreen1_sheep/iotMeteorologicalList.vue'
import SheepUser from './components/dataScreen1_sheep/sheepUser.vue'
import enterprise from './components/dataScreen1_sheep/enterprise.vue'
import SheepPrice from './components/dataScreen1_sheep/sheepPrice.vue'
import SheepShop from './components/dataScreen1_sheep/sheepShop.vue'
// import SheepEvaluationPrice from './components/dataScreen1_sheep/sheepEvaluationPrice.vue'
import SheepEvaluationPrice from './components/dataScreen1_sheep/sheepEvaluationPriceHold.vue'
import SheepEvaluationAveragePrice from './components/dataScreen1_sheep/sheepEvaluationAveragePrice.vue'


export default {
    name: '',
    data(){
        return{

        }
    },
    components: {
        BasicInformation,
        SheepIndustry,
        SheepUser,
        CameraList,
        IotMeteorologicalList,
        enterprise,
        SheepPrice,
        SheepShop,
        SheepEvaluationPrice,
        SheepEvaluationAveragePrice
    },
    mounted(){

    },
    methods:{
        
    }
}
</script>

<style scoped>
.menu_title {
    width: 100%;
    height: 146px;
    border: 1px solid #0091FF;
    background: rgba(25, 145, 252, 0.1);
    padding: 12px 20px;
    display: flex;
    box-sizing: border-box;
  }
  .descrip {
      font-size: 14px;
      color: #1991FC;
      flex-shrink: 0;
      width: 100px;
  }
  .descrip_content {
      font-size: 14px;
      color: rgba(29, 30, 31, 0.6);
      line-height: 14px;
  }
  .descrip_content >p {
      margin-bottom: 12px;
  }
  .setting_content {
      /* padding: 24px; */
      background: #fff;
  }
  .setting_title {
      padding: 24px;
      padding-bottom: 6px;
  }
  .setting_content_content {
      padding: 24px;
  }
  .bootom {
      width: calc(100% - 254px);
      height: 44px;
      position: fixed;
      bottom: 0;
      left: 230px;
      background: #fff;
      margin-left: -24px;
      text-align: center;
      line-height: 44px;
  }
  .sub-title {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .disease-box {
    position: relative;
    
    border: 1px solid #ccc;
    box-sizing: border-box;
    padding: 20px;
  }
  .el-icon-close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 50;
  }
  .line {
    width: 100%;
    height: 1px;
    margin: 30px 0;
    background: #ccc;
  }

  .avatar-uploader {
    width: 150px;
    height: 162px;
    border: 1px dashed #ccc;
    text-align: center;
  }
  .avatar-uploader .avatar {
    width: 150px;
    height: 162px;
  }
</style>