<template>
    <div>
    <div class="sub-title">用户数据</div>
        <el-button type="primary" style="min-width: 88px;" @click="add">新增</el-button>
        <el-table border :data="tableData" class="mt20">
            <el-table-column prop="whirlyName" label="用户"> </el-table-column>
            <el-table-column prop="mapX" label="x轴(px)"> </el-table-column>
            <el-table-column prop="mapY" label="y轴(px)"> </el-table-column>
            <el-table-column prop="name" label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="edit(scope.row)" size="small">修改</el-button>
                    <el-button type="text" @click="remove(scope.row.id)" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="overflow: hidden;">
            <div style="margin-top: 12px; float: right">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.total">
                </el-pagination>
            </div>
        </div>
        <!-- <el-row class="ta_c">
            <el-button type="primary" class="mt20" style="min-width: 88px;" @click="onSaveFootInfo">保存</el-button>
        </el-row> footerPageInfo -->

        <el-dialog title="新增" width="500px" :visible.sync="dialog">
            <el-form  label-width="120px">
                <el-form-item label="用户">
                    <el-input v-model="form.whirlyName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item prop="mapX" label="x轴(px)">
                    <el-input type="number" v-model="form.mapX" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item  prop="mapY" label="y轴(px)">
                    <el-input type="number" v-model="form.mapY" autocomplete="off"></el-input>
                </el-form-item>
                
                <div class="dialog-footer" style="text-align: right">
                    <el-button @click="dialog = false">取 消</el-button>
                    <el-button type="primary" @click="addSubmit">确 定</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: '',
    data(){
        return{
            pageInfo: {
                pageNo: 1,
                pageSize: 10,
                total: 0
            },
            tableData: [],
            dialog: false,
            form: {
                whirlyName:'',
                mapX: '',
                mapY: '',
            },
            isEdit: false,
        }
    },

    mounted(){
        this.getInfo()
    },
    methods:{
        getInfo() {
            //查询预警列表
            this.qa.datascreen1WhirlyQueryForPageList(this.pageInfo).then(res => {
                if(res.respCode == '0000') {
                    this.tableData = res.data;
                    this.pageInfo.total = res.total;
                }
            })
        },
        handleSizeChange(page){
          this.pageInfo.pageSize = page;
          this.getInfo()
        },
        handleCurrentChange(page) {
            this.pageInfo.pageNo = page;
            this.getInfo()
        },
        add() {
            this.form = {
                whirlyName:'',
                mapX: '',
                mapY: '',
            }
            this.dialog = true
        },
        addSubmit() {
            if (this.isEdit) {
                this.qa.datascreen1WhirlyQueryForDoUpdate(this.form).then(res => {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.dialog = false;
                    this.getInfo()
                })
            } else {
                this.qa.datascreen1WhirlyQueryForDoInsert(this.form).then(res => {
                    this.$message({
                        message: '新增成功',
                        type: 'success'
                    });
                    this.dialog = false;
                    this.getInfo()
                })
            }
        },
        edit (row) {
            this.form = row;
            this.dialog = true;
            this.isEdit = true;
        },
        remove(id) {
            this.qa.datascreen1WhirlyQueryForDoDeleteById({id: id}).then(res => {
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.getInfo()
            })
        }
    }
}
</script>

<style scoped>
    .sub-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 20px;
    }
</style>