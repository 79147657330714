<template>
    <div>
    <div class="sub-title">榆阳区羊肉价格指示图</div>
        <el-button type="primary" style="min-width: 88px;" @click="add">新增</el-button>
        <el-table border :data="tableData" class="mt20">
            <el-table-column prop="mnaaa" label="价格"> </el-table-column>
            <el-table-column prop="mnbbb" label="单位"> </el-table-column>
            <el-table-column prop="mnccc" label="x轴(px)"> </el-table-column>
            <el-table-column prop="mnddd" label="y轴(px)"> </el-table-column>
            <el-table-column prop="name" label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="remove(scope.row.id)" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="overflow: hidden;">
            <div style="margin-top: 12px; float: right">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.total">
                </el-pagination>
            </div>
        </div>
        <!-- <el-row class="ta_c">
            <el-button type="primary" class="mt20" style="min-width: 88px;" @click="onSaveFootInfo">保存</el-button>
        </el-row> footerPageInfo -->

        <el-dialog title="新增" width="500px" :visible.sync="dialog">
            <el-form  label-width="120px">
                 <el-form-item label="价格" >
                    <el-input type="number" v-model="form.mnaaa" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="单位">
                    <el-input v-model="form.mnbbb" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item prop="mnccc" label="x轴(px)">
                    <el-input type="number" v-model="form.mnccc" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item  prop="mnddd" label="y轴(px)">
                    <el-input type="number" v-model="form.mnddd" autocomplete="off"></el-input>
                </el-form-item>
                
                <div class="dialog-footer" style="text-align: right">
                    <el-button @click="dialog = false">取 消</el-button>
                    <el-button type="primary" @click="addSubmit">确 定</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: '',
    data(){
        return{
            pageInfo: {
                pageNo: 1,
                pageSize: 10,
                total: 0
            },
            tableData: [],
            dialog: false,
            form: {
                mnaaa:'',
                mnbbb: '',
                mnccc: '',
                mnddd: '',
            }
        }
    },

    mounted(){
        this.getInfo()
    },
    methods:{
        getInfo() {
            //查询预警列表
            this.qa.queryForPageListDisease(this.pageInfo).then(res => {
                if(res.respCode == '0000') {
                    this.tableData = res.data;
                    this.pageInfo.total = res.total;
                }
            })
        },
        handleSizeChange(page){
          this.pageInfo.pageSize = page;
          this.getInfo()
        },
        handleCurrentChange(page) {
            this.pageInfo.pageNo = page;
            this.getInfo()
        },
        add() {
            this.form = {
                mnaaa:'',
                mnbbb: '',
                mnccc: '',
                mnddd: '',
            }
            this.dialog = true
        },
        addSubmit() {
            this.qa.doInsertDisease(this.form).then(res => {
                this.$message({
                    message: '保存成功',
                    type: 'success'
                });
                this.dialog = false;
                this.getInfo()
            })
        },
        remove(id) {
            this.qa.doDeleteByIdDisease({id: id}).then(res => {
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.getInfo()
            })
        }
    }
}
</script>

<style scoped>
    .sub-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 20px;
    }
</style>