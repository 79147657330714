import { render, staticRenderFns } from "./dataScreen1_sheep.vue?vue&type=template&id=6efbf9ba&scoped=true&"
import script from "./dataScreen1_sheep.vue?vue&type=script&lang=js&"
export * from "./dataScreen1_sheep.vue?vue&type=script&lang=js&"
import style0 from "./dataScreen1_sheep.vue?vue&type=style&index=0&id=6efbf9ba&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6efbf9ba",
  null
  
)

export default component.exports