<template>
    <div>
        <div class="sub-title">基础信息</div>
        <el-form :inline="true" label-width="193px" :model="form" class="demo-form-inline">
            <el-row>
                <el-col :span="6">
                    <el-form-item label="年饲养量（只）">
                        <el-input v-model="form.yearFeedingQuantity"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="年存栏量（只）">
                        <el-input v-model="form.yearAnnualInventory"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="年出栏量（只）">
                        <el-input v-model="form.yearAnnualSales"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- <el-row>
                <el-form-item label="基地画面名称1">
                    <el-input  v-model="form.shifanjidiVedio1Title"></el-input>
                </el-form-item>
                <el-form-item label="基地实时画面1">
                    <el-input style="width: 550px" v-model="form.shifanjidiVedio1Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="基地画面名称2">
                    <el-input  v-model="form.shifanjidiVedio2Title"></el-input>
                </el-form-item>
                <el-form-item label="基地实时画面2">
                    <el-input style="width: 550px" v-model="form.shifanjidiVedio2Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="基地画面名称3">
                    <el-input  v-model="form.shifanjidiVedio3Title"></el-input>
                </el-form-item>
                <el-form-item label="基地实时画面3">
                    <el-input style="width: 550px" v-model="form.shifanjidiVedio3Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="基地画面名称4">
                    <el-input  v-model="form.shifanjidiVedio4Title"></el-input>
                </el-form-item>
                <el-form-item label="基地实时画面4">
                    <el-input style="width: 550px" v-model="form.shifanjidiVedio4Url"></el-input>
                </el-form-item>
            </el-row> -->
        </el-form>
        <el-row class="ta_c">
            <el-button type="primary" style="min-width: 88px;" @click="onSaveInfo">保存</el-button>
        </el-row>

    </div>
</template>

<script>

export default {
    name: '',
    data(){
        return{
            form: {
            }
        }
    },
    
    mounted(){
        this.getInfo()
    },
    methods:{
        getInfo() {
            this.qa.loadDataBOSheepBasicInformation1({}).then(res => {
                if(res.respCode == '0000') {
                    this.form = res.data;
                }
            })
        },
        onSaveInfo() {
            this.qa.doSaveSheepBasicInformation1(this.form).then(res => {
                if(res.respCode == '0000') {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.getInfo()
                }
            })
        }
    }
}
</script>

<style scoped>
    .sub-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 20px;
    }
</style>