<template>
    <div>
    <div class="sub-title">羊肉平均价格</div>
        <el-button type="primary" style="min-width: 88px;" @click="add">新增</el-button>
        <el-table border :data="tableData" class="mt20">
            <el-table-column prop="year" label="年份"> </el-table-column>
            <el-table-column prop="month" label="月份"> </el-table-column>
            <el-table-column prop="avgPrice" label="平均价"> </el-table-column>
            <el-table-column prop="name" label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="edit(scope.row)" size="small">修改</el-button>
                    <el-button type="text" @click="remove(scope.row.id)" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="overflow: hidden;">
            <div style="margin-top: 12px; float: right">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.total">
                </el-pagination>
            </div>
        </div>
        <el-dialog title="新增" class="pop" width="500px" :visible.sync="dialog">
            <el-form  label-width="120px">
                <el-form-item label="年份">
                  <el-date-picker
                        style="width: 100%;"
                        v-model="form.year"
                        @change="yearFun"
                        type="year"
                        value-format="yyyy"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="月份">
                  <el-date-picker
                        style="width: 100%;"
                        v-model="form.month"
                        :default-value="form.year"
                        @change="monthFun"
                        type="month"
                        format="MM"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="平均价">
                    <el-input v-model="form.avgPrice" autocomplete="off"></el-input>
                </el-form-item>
                <div class="dialog-footer" style="text-align: right">
                    <el-button @click="dialog = false">取 消</el-button>
                    <el-button type="primary" @click="addSubmit">确 定</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: '',
    data(){
        return{
            title: '新增',
            pageInfo: {
                pageNo: 1,
                pageSize: 10,
                total: 0
            },
            tableData: [],
            dialog: false,
            form: {
                year: '',
                month: '',
                avgPrice: '',
            }
        }
    },

    mounted(){
        this.getInfo()
    },
    methods:{
        yearFun (val) {
            this.form.month = '';
        },
        monthFun (value) {
            console.log(value)
        },
        formDate (data) {
            let date1 = data.getTime() 
            var date = new Date(date1);
            // let Y = date.getFullYear() + '年';
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
            // let D = date.getDate() + '日';
            let date2 = M;
            return date2
        },
        getInfo() {
            //查询
            this.qa.datascreen1AvgPriceQueryForPageList(this.pageInfo).then(res => {
                if(res.respCode == '0000') {
                    this.tableData = res.data;
                    this.pageInfo.total = res.total;
                }
            })
        },
        handleSizeChange(page){
            this.pageInfo.pageSize = page;
            this.getInfo()
        },
        handleCurrentChange(page) {
            this.pageInfo.pageNo = page;
            this.getInfo()
        },
        edit(row) {
            this.title = '修改'
            this.form = JSON.parse(JSON.stringify(row))
            this.form.month = new Date(this.form.year + '-' + this.form.month);
            this.dialog = true
        },
        add() {
            this.title = '新增'
            this.form = {
                year: '',
                month: '',
                avgPrice: '',
            }
            this.dialog = true
        },
        addSubmit() {
            this.form.month = this.formDate(this.form.month);                            
            if(this.title == '新增') {
                this.qa.datascreen1AvgPriceDoInsert(this.form).then(res => {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.dialog = false;
                    this.getInfo()
                })
            }else {
                this.qa.datascreen1AvgPriceDoUpdate(this.form).then(res => {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.dialog = false;
                    this.getInfo()
                })
            }
        },
        remove(id) {
            this.qa.datascreen1AvgPriceDoDeleteById({id: id}).then(res => {
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.getInfo()
            })
        }
    }
}
</script>

<style scoped>
    .sub-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 20px;
    }
    
    .pop >>> .el-time-spinner {
        box-sizing: border-box;
        padding-left: 20px;
    }
</style>